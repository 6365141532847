<template>
  <div class="arttextstyle">
    <div class="arttextstyle__wrapper">
      <div
        class="arttextstyle__background"
        :style="{
          backgroundImage: `url(${arttextstyle.header_image})`,
        }"
      ></div>
      <div class="arttextstyle__main-container">
        <div class="arttextstyle__details-box" ref="titleBox">
          <div class="arttextstyle__details-box_tags" ref="tags">
            <Tag
              class="tag--public"
              v-for="tag in getVisibleTags"
              :key="`tag-${tag.id}`"
              :value="tag.title"
              @click="clickTag(tag)"
            />
            <AdditionalInfo
              v-if="getHiddenTags.length"
              class="additional-tags--public"
              :items="getHiddenTags"
              :parentClientRect="tagsClientRect"
              :isPublic="true"
              :isSearch="false"
              @getParentClientRect="getParentClientRect"
              @changeFilterValue="clickTag"
            ></AdditionalInfo>
          </div>
          <h2 class="arttextstyle__details-box_title">
            {{ arttextstyle.title }}
          </h2>
          <p class="arttextstyle__details-box_date">
            <span>
              {{ arttextstyle.date_published | _moment("MMM DD YYYY", "YYYY-MM-DD, hh:mm a") }}
            </span>
            <span class="arttextstyle__details-box_date--devider"> | </span>
            <span> by {{ arttextstyle.author }} </span>

            <span class="arttextstyle__details-box_date--devider"> | </span>
            <span>{{ arttextstyle.read_time || "5 min" }} read</span>
          </p>
        </div>

        <div class="arttextstyle__body">
          <div
            class="arttextstyle__body_description collect-editor collect-editor--white"
            v-html="arttextstyle.details"
          ></div>
          <div class="arttextstyle__body_footer">
            <p class="arttextstyle__body_footer-date">
              <span>
                {{ arttextstyle.date_published | _moment("MMM DD YYYY", "YYYY-MM-DD, hh:mm a") }}
              </span>
              <span class="arttextstyle__body_footer-date--devider">|</span>
              <span>by {{ arttextstyle.author }}</span>
              <span class="arttextstyle__body_footer-date--devider">|</span>
              <span> {{ arttextstyle.read_time || "5min" }} read</span>
            </p>

            <ShareButton class="artist__header_share" />
          </div>
          <div class="arttextstyle__body_comments">
            <p>Comments ({{ arttextstyle.comments.length }})</p>
            <div class="arttextstyle__body_comments-scrolled">
              <div class="comment" v-for="comment in arttextstyle.comments" :key="comment.id">
                <div class="comment__header">
                  <h4>{{ comment.author }}</h4>
                  <p>{{ comment.date }}</p>
                </div>
                <div class="comment__body">
                  <p>{{ comment.comment }}</p>
                </div>
              </div>
            </div>
            <div class="arttextstyle__body_comments-footer">
              <BaseButton
                class="button button--xs button--white"
                text="POST A COMMENT"
                @click="modal = true"
              />
              <h3>{{ arttextstyle.title }}</h3>
            </div>
          </div>
          <div class="arrows">
            <div
              class="arrow arrow--left"
              :class="{ 'arrow--disabled': !prevNextslug.prev }"
              @click="
                prevNextslug.prev
                  ? $router.push({ path: `/media/arttextstyle/${prevNextslug.prev}` })
                  : false
              "
            >
              <span class="arrow__circle arrow__circle--left">
                <IconArrowTop width="15" height="12" fill="#000"></IconArrowTop>
              </span>
              <span class="arrow__text">Previous Post</span>
            </div>
            <div
              class="arrow arrow--left"
              :class="{ 'arrow--disabled': !prevNextslug.next }"
              @click="
                prevNextslug.next
                  ? $router.push({ path: `/media/arttextstyle/${prevNextslug.next}` })
                  : false
              "
            >
              <span class="arrow__text">Next Post</span>
              <span class="arrow__circle arrow__circle--right">
                <IconArrowTop width="15" height="12" fill="#000"></IconArrowTop>
              </span>
            </div>
          </div>
        </div>
        <template v-if="threeRandomPosts.length">
          <h3 class="arttextstyle__arttextstyle-box_title">More arttextstyle</h3>
          <div class="arttextstyle__arttextstyle-box">
            <router-link
              v-for="(item, key) in threeRandomPosts"
              :key="`${key}-item`"
              :to="{ path: `/media/arttextstyle/${item.slug}` }"
              v-slot="{ navigate }"
              custom
            >
              <div class="arttextstyle__arttextstyle-box_card" @click="navigate">
                <div
                  class="image"
                  :style="{
                    backgroundImage: `url(${item.search_image})`,
                  }"
                  @click="$router.push({ path: `/media/arttextstyle/${item.slug}` })"
                >
                  <div class="image__preview"><p>Click to View</p></div>
                </div>
                <span class="tag">arttextstyle</span>
                <h3
                  class="title"
                  @click="$router.push({ path: `/media/arttextstyle/${item.slug}` })"
                >
                  {{ item.title }}
                </h3>
                <span class="date"
                  >{{ item.date_published | _moment("MMM DD YYYY", "YYYY-MM-DD, hh:mm a") }}
                </span>
              </div>
            </router-link>
          </div>
        </template>
      </div>
    </div>
    <CommentPopUp
      v-if="modal"
      :modal="modal"
      :title="arttextstyle.title"
      @hideModal="modal = false"
      @createComment="createComment"
    ></CommentPopUp>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import BaseButton from "@/components/buttons/BaseButton.vue";
import PublicSelect from "@/components/inputs/PublicSelect.vue";
import Tag from "@/components/Tag.vue";
import AdditionalInfo from "@/components/AdditionalInfo.vue";
import ShareButton from "@/components/ShareButton.vue";
import IconArrowTop from "@/components/icons/IconArrowTop.vue";
import CommentPopUp from "./CommentPopUp.vue";

export default {
  components: {
    BaseButton,
    PublicSelect,
    Tag,
    AdditionalInfo,
    ShareButton,
    IconArrowTop,
    CommentPopUp,
  },

  data() {
    return {
      tagsClientRect: { bottom: 0, right: 0 },
      modal: false,
      cardData: {
        slug: null,
        sort: "newest_to_oldest",
        tags: "",
        page: 1,
      },
      getVisibleTags: [],
      getHiddenTags: [],
    };
  },
  head: {
    title: function () {
      return {
        inner: this.arttextstyle.title ?? "Arttextstyle browngrotta arts",
      };
    },
    meta: function () {
      return [
        {
          name: "description",
          itemprop: "description",
          content: `${this.arttextstyle.title ?? "Arttextstyle browngrotta arts"} - ${
            this.arttextstyle.details ?? "Description Arttextstyle browngrotta arts"
          }`,
          id: "meta:description",
        },
        {
          itemprop: "name",
          content: this.arttextstyle.title ?? "Arttextstyle browngrotta arts",
          id: "meta:name",
        },
        {
          itemprop: "image",
          content:
            this.arttextstyle.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "meta:image",
        },
        {
          property: "og:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "og:url",
        },
        {
          property: "og:type",
          content: "website",
          id: "og:type",
        },
        {
          property: "og:title",
          content: this.arttextstyle.title ?? "Arttextstyle browngrotta arts",
          id: "og:title",
        },
        {
          property: "og:image",
          content:
            this.arttextstyle.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "og:image",
        },
        {
          property: "og:image:alt",
          content: this.arttextstyle.title ?? "Arttextstyle browngrotta arts",
          id: "og:image:alt",
        },
        {
          property: "og:description",
          content: this.arttextstyle.details ?? "Description Arttextstyle browngrotta arts",
          id: "og:description",
        },
        {
          property: "og:site_name",
          content: process.env.VUE_APP_FRONTEND_URL,
          id: "og:site_name",
        },
        {
          property: "og:locale",
          content: "en_US",
          id: "og:locale",
        },
        {
          name: "twitter:card",
          content: "summary",
          id: "twitter:card",
        },
        {
          name: "twitter:site",
          content: "@BrowngrottaArts",
          id: "twitter:site",
        },
        {
          name: "twitter:creator",
          content: "@BrowngrottaArts",
          id: "twitter:creator",
        },
        {
          name: "twitter:url",
          content: `${process.env.VUE_APP_FRONTEND_URL}${this.$route.path}`,
          id: "twitter:url",
        },
        {
          name: "twitter:title",
          content: this.arttextstyle.title ?? "Arttextstyle browngrotta arts",
          id: "twitter:title",
        },
        {
          name: "twitter:description",
          content: this.arttextstyle.details ?? "Description Arttextstyle browngrotta arts",
          id: "twitter:description",
        },
        {
          name: "twitter:image",
          content:
            this.arttextstyle.header_image ??
            `${process.env.VUE_APP_FRONTEND_URL}/img/icons/favicon-500x500.png`,
          id: "twitter:image",
        },
        {
          name: "twitter:image:alt",
          content: this.arttextstyle.title ?? "Arttextstyle browngrotta arts",
          id: "twitter:image:alt",
        },
      ];
    },
  },
  async created() {
    await this.createPageInfo(this.$route.params.slug);
    this.$emit("updateHead");
  },
  computed: {
    ...mapGetters("publicapi/blogs", {
      arttextstyle: "getArttextstyle",
      prevNextslug: "getPrevNextSlug",
      threeRandomPosts: "getThreeRandomPosts",
    }),
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.createPageInfo(val.params.slug);
        this.$emit("updateHead");
      },
      deep: true,
    },
    tagsData: {
      handler: function (val, oldVal) {
        let tags = "";
        val.forEach(el => {
          if (el.selected) {
            tags = `${el.id},${tags}`;
          }
        });

        this.cardData.tags = tags;
      },
      deep: true,
    },
    arttextstyle: {
      handler: function () {
        this.calculateVisibleTags();
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations(["setBreadcrumbs"]),
    ...mapMutations("publicapi/share", ["setShareData"]),
    ...mapActions("publicapi/blogs", ["apiGetSpecificArttextstyle", "apiPostComment"]),
    async createPageInfo(slug) {
      this.cardData.slug = slug;
      await this.apiGetSpecificArttextstyle(slug);
      const breadcrumbs = [
        {
          title: "Home",
          to: "/",
        },
        {
          title: "Media",
          to: "/media",
          clickable: true,
        },
        {
          title: "Arttextstyle",
          to: "/media/arttextstyle",
          clickable: true,
        },
        {
          title: this.arttextstyle.title,
          clickable: false,
        },
      ];
      this.setBreadcrumbs(breadcrumbs);

      this.setShareData({
        title: this.arttextstyle.title,
        description: this.arttextstyle.details,
        image: this.arttextstyle.header_image,
      });
    },
    async createComment(val) {
      let result = await this.apiPostComment({ ...val, art_text_style_id: this.arttextstyle.id });
      if (result) {
        await this.createPageInfo(this.$route.params.slug);
        this.modal = false;
      }
    },
    getParentClientRect() {
      this.tagsClientRect = this.$refs.titleBox.getBoundingClientRect();
    },
    clickTag(tag) {
      this.$router.push({
        path: "/content",
        query: { tag_id: tag.id },
      });
    },
    calculateVisibleTags() {
      const width = this.$refs.tags.offsetWidth;
      const tags = this.arttextstyle?.tags_objects ?? [];
      let allSize = 10; // min size
      let lastIndex = 0;
      for (let index = 0; index < tags.length; index++) {
        const size = 50 + tags[index].title.length * 8; // margin + pading + string.length * font size
        allSize += size;
        if (width <= allSize) break;
        lastIndex = index + 1;
      }
      this.getVisibleTags = tags.slice(0, lastIndex) ?? []; // tags.slice(0, 6) ?? [];
      this.getHiddenTags = tags.slice(lastIndex, tags.length) ?? []; // tags.slice(6, this.arttextstyle.tags_objects.length) ?? [];
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  color: $white;
  font-size: 1.8rem;
  line-height: 2.4rem;
  @media screen and (max-width: $xs) {
    font-size: 1.6rem;
    line-height: 2.5rem;
  }
}

.arttextstyle {
  display: flex;

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 58.4rem;
    z-index: 0;
    background-size: auto 100%;
    background-position: center;
    background-repeat: no-repeat;
    @media screen and (max-width: $xs) {
      height: 33.5rem;
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $lightBlack, $alpha: 0.45);
      z-index: 1;
    }
    &::after {
      content: "";
      bottom: -1px;
      position: absolute;
      width: 100%;
      height: 50%;
      background: linear-gradient(
        0deg,
        rgba($color: $lightBlack, $alpha: 1) 0%,
        rgba($color: $lightBlack, $alpha: 0) 100%
      );
      z-index: 1;
    }
  }

  &__body {
    width: 72%;
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 100%;
      padding-top: 55px;
    }
    &_description {
      &::v-deep p,
      &::v-deep p span {
        font-size: 2.3rem;
        line-height: 1.55;
        @media screen and (max-width: $md) {
          font-size: 16px;
        }
      }
    }
    &_comments {
      padding-top: 25px;
      padding-bottom: 55px;
      border-top: 1px solid $white;
      & > p {
        font-family: $fontFrutiger;
        font-weight: 500;
        font-size: 16px;
        line-height: 1.55;
        text-transform: uppercase;
        margin-bottom: 35px;
      }
      &-scrolled {
        width: calc(100% + 15px);
        max-height: 600px;
        overflow-y: auto;
        padding-right: 15px;
      }
      .comment {
        padding: 20px 0;
        border-bottom: 1px solid $white;
        margin-bottom: 10px;
        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 30px;
          @media screen and (max-width: $sm) {
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 20px;
          }
          h4 {
            font-family: $fontFrutiger;
            font-weight: 500;
            font-size: 20px;
            line-height: 1.4;
            @media screen and (max-width: $md) {
              font-size: 18px;
            }
          }
          p {
            font-family: $fontFrutiger;
            font-size: 14px;
            line-height: 2;
          }
        }
        &__body {
          p {
            font-family: $fontFrutiger;
            font-size: 18px;
            font-weight: 300;
            line-height: 1.55;
            @media screen and (max-width: $md) {
              font-size: 16px;
            }
          }
        }
      }
      &-footer {
        display: flex;
        align-items: center;
        padding: 27px 0;
        @media screen and (max-width: $sm) {
          flex-direction: column-reverse;
        }
        .button {
          margin-right: 35px;
        }
        h3 {
          font-family: $fontFrutiger;
          font-weight: 500;
          font-size: 24px;
          line-height: 1.1;
          @media screen and (max-width: $md) {
            font-size: 18px;
          }
          @media screen and (max-width: $sm) {
            text-align: center;
            margin-bottom: 25px;
          }
        }
      }
    }
    &_footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 0 55px;
      @media screen and (max-width: $sm) {
        flex-direction: column;
      }
      &-date {
        display: flex;
        font-size: 14px;
        line-height: 1.8;
        text-transform: uppercase;
        &--devider {
          text-align: center;
          width: 25px;
          display: block;
        }
        @media screen and (max-width: $sm) {
          margin-bottom: 15px;
        }
      }
    }
  }

  &__wrapper {
    position: relative;
    min-height: 100vh;
    min-width: 100vw;
  }

  &__main-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 36rem;
    padding: 0rem 10.5rem 23.5rem;
    z-index: 2;
    @media screen and (max-width: $md) {
      padding: 0 5rem 24rem;
      margin-top: 24.5rem;
    }
    @media only screen and (max-width: $sm) {
      padding: 0 25px 24rem;
    }
  }

  &__details-box {
    display: flex;
    flex-direction: column;
    width: 72%;
    @media screen and (max-width: $md) {
      width: 100%;
    }
    .button {
      margin-bottom: 15px;
    }
    &_tags {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0 15px -10px;
    }
    &_title {
      font-size: 5.2rem;
      line-height: 1.25;
      margin-bottom: 5px;
      @media only screen and (max-width: $md) {
        font-size: 35px;
      }
      @media screen and (max-width: $xs) {
        font-size: 30px;
      }
    }
    &_date {
      display: flex;
      font-size: 14px;
      line-height: 1.8;
      margin-bottom: 25px;
      text-transform: uppercase;
      &--devider {
        text-align: center;
        width: 25px;
        display: block;
      }
    }
    &_details {
      font-size: 2.35rem;
      line-height: 1.55;
      margin-bottom: 35px;
    }
  }

  &__title-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin: 11rem 0rem 5rem;
    @media screen and (max-width: $xs) {
      margin: 8.5rem 0rem 3rem;
    }
    &_right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-wrap: wrap;
    }
    &_title {
      font-size: 4rem;
      line-height: 5rem;
      margin: 5px;
      @media screen and (max-width: $xs) {
        font-size: 3.5rem;
        line-height: 4.3rem;
      }
    }
  }

  &__arttextstyle-box {
    // display: flex;
    // flex-wrap: wrap;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 7rem;
    grid-row-gap: 7rem;
    @media screen and (max-width: $md) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (max-width: $xs) {
      grid-template-columns: 1fr;
      grid-row-gap: 7rem;
    }
    &_title {
      width: 100%;
      font-size: 5.2rem;
      line-height: 1.25;
      margin: 55px 0;
      @media screen and (max-width: $md) {
        font-size: 35px;
      }
      @media screen and (max-width: $xs) {
        font-size: 30px;
      }
    }
    &_card {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      .image {
        position: relative;
        height: 27vw;
        width: 100%;
        background-size: cover;
        background-position: center;
        margin-bottom: 25px;
        border-radius: 4px;
        @media screen and (max-width: $md) {
          height: 33vw;
        }
        @media screen and (max-width: $sm) {
          height: 70vw;
        }
        @media screen and (max-width: $xxxs) {
          height: 100vw;
        }
        &__preview {
          position: absolute;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-self: center;
          opacity: 0;
          border-radius: 4px;
          transition: all 0.3s ease;
          p {
            width: 100%;
            text-align: center;
            font-size: 3rem;
            line-height: 1.67;
            letter-spacing: -1px;
          }
        }
        &:hover {
          .image__preview {
            opacity: 1;
            background-color: rgba($color: $blue, $alpha: 0.83);
          }
        }
      }
      .tag {
        display: flex;
        justify-content: center;
        align-content: center;
        width: max-content;
        text-transform: uppercase;
        border: 1px solid $white;
        border-radius: 5px;
        font-size: 14px;
        line-height: 1.8;
        padding: 0.1rem 1rem;
        margin-bottom: 10px;
      }
      .title {
        font-size: 3.125rem;
        line-height: 1.3;
        margin-bottom: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
        @media screen and (max-width: $xxl) {
          font-size: 24px;
        }
        @media screen and (max-width: $xs) {
          font-size: 22px;
        }
        // &:hover {
        //   text-decoration: underline;
        // }
      }
      .date {
        font-size: 14px;
        line-height: 1.8;
        text-transform: uppercase;
      }
    }
  }

  &__button-box {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    margin-top: 7rem;
    .button {
      min-width: 180px;
    }
  }
}

.arrows {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  padding: 70px 0;
  @media screen and (max-width: $md) {
    padding: 40px 0;
  }
  @media screen and (max-width: $sm) {
    padding: 30px 0;
  }
  .arrow {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &__circle {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: $white;
      padding-bottom: 2px;
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        width: 35px;
        height: 35px;
      }
      &--left {
        transform: rotate(-90deg);
      }
      &--right {
        transform: rotate(90deg);
      }
    }
    &__text {
      padding: 0 15px;
      font-size: 3.125rem;
      font-weight: 500;
      line-height: 1.1;
      @media screen and (max-width: $md) {
        font-size: 20px;
      }
      @media screen and (max-width: $xxxs) {
        display: none;
      }
    }
    &:hover {
      .arrow__circle {
        background-color: $light2Grey;
      }
      .arrow__text {
        color: $light2Grey;
      }
    }
    &--disabled {
      cursor: default;
      opacity: 0.5;
    }
  }
}
</style>
