<template>
  <PopUp
    class="modal--m"
    bgColor="rgba(0,0,0)"
    :themeLight="false"
    :menu="true"
    :show="modal"
    @hideModal="hideModal"
  >
    <ValidationObserver
      ref="observer"
      tag="div"
      id="scroll-block"
      class="comment"
      v-slot="{ invalid }"
    >
      <header class="comment__header">
        <h2>Post a Comment</h2>
        <h3>
          {{ title }}
        </h3>
      </header>
      <div class="comment__body">
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              class="form-control--simple"
              placeholder="Your Name"
              :required="true"
              :validateError="errors[0]"
              v-model="comment.author"
              :element="comment.author"
              @input="comment.author = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, email: true }"
            v-slot="{ errors }"
          >
            <BaseInput
              class="form-control--simple"
              placeholder="Your Email"
              :required="true"
              :validateError="errors[0]"
              v-model="comment.email"
              :element="comment.email"
              @input="comment.email = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ required: true, min: 1, max: 199 }"
            v-slot="{ errors }"
          >
            <BaseTextarea
              class="form-control--simple"
              placeholder="Your Comment"
              :required="true"
              maxLength="199"
              :validateError="errors[0]"
              v-model="comment.comment"
              :element="comment.comment"
              @input="comment.comment = $event"
            ></BaseTextarea>
          </ValidationProvider>
        </div>
      </div>
      <div class="comment__actions">
        <div class="comment__actions_left">
          <BaseButton
            v-if="captchaVerifided"
            textColor="#000"
            class="button--sm button--white button--uppercase"
            text="post a comment"
            :loading="commentLoading"
            @click="$emit('createComment', comment)"
            :disabled="invalid"
          ></BaseButton>
          <VueRecaptcha
            v-else
            ref="recaptcha"
            :sitekey="sitekey"
            @verify="captchaVerifided = true"
            @error="captchaVerifided = false"
          />
        </div>
        <BaseCheckbox
          label="Subscribe to arttextstyle email notifications"
          :checked="comment.is_subscribe_to_mailchimp"
          @input="comment.is_subscribe_to_mailchimp = $event"
        ></BaseCheckbox>
      </div>
      <p class="comment__footer">
        Your email address will not be published. Required fields are marked*
      </p>
    </ValidationObserver>
  </PopUp>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseInput from "@/components/inputs/BaseInput.vue";
import BaseTextarea from "@/components/inputs/BaseTextarea.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import { VueRecaptcha } from "vue-recaptcha";
import { mapGetters } from "vuex";

export default {
  props: {
    modal: { type: Boolean, default: false },
    title: { type: String },
  },
  components: {
    PopUp,
    ValidationObserver,
    ValidationProvider,
    BaseButton,
    BaseInput,
    BaseTextarea,
    BaseCheckbox,
    VueRecaptcha,
  },
  data() {
    return {
      captchaVerifided: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      comment: {
        author: "",
        email: "",
        comment: "",
        is_subscribe_to_mailchimp: false,
      },
      changed: false,
    };
  },
  watch: {
    comment: {
      handler: function (val, oldVal) {
        if (this.$refs.observer.flags.dirty) {
          this.changed = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("publicapi/blogs", {
      commentLoading: "getCommentLoading",
    }),
  },
  methods: {
    hideModal() {
      if (this.changed) {
        this.$swal({
          html: "<h2>Are you sure you want to exit <br/> without saving?</h2>",
          showCancelButton: true,
          cancelButtonText: "Exit without saving",
          confirmButtonText: "Save changes",
        }).then(result => {
          if (result.value) {
            this.captchaVerifided
              ? this.$refs.observer.flags.valid
                ? this.$emit("createComment", this.comment)
                : this.$toasted.show(`Please fill in all required fields`, {
                    duration: 2000,
                    type: "error",
                  })
              : this.$toasted.show(`Confirm that you are not a robot`, {
                  duration: 2000,
                  type: "error",
                });
          } else this.$emit("hideModal");
        });
      } else this.$emit("hideModal");
    },
  },
};
</script>
<style lang="scss" scoped>
.comment {
  max-height: 80vh;
  overflow-y: auto;
  padding: 0 10rem;
  margin: 20px 0 0;
  @media only screen and (max-width: $md) {
    padding: 0 8rem;
  }
  @media only screen and (max-width: $sm) {
    max-height: 85vh;
    padding: 0 30px;
    margin: 30px 0 0;
  }
  &__header {
    max-width: 80rem;
    h2 {
      font-size: 5.2rem;
      line-height: 1.25;
      font-weight: 500;
      margin-bottom: 25px;
      @media only screen and (max-width: $md) {
        font-size: 4rem;
      }
      @media only screen and (max-width: $sm) {
        font-size: 35px;
      }
    }
    h3 {
      font-size: 3.125rem;
      line-height: 1.3;
      font-weight: 500;
      margin-bottom: 22px;
      @media only screen and (max-width: $md) {
        font-size: 25px;
      }
      @media only screen and (max-width: $sm) {
        font-size: 18px;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 35px 0 65px;
    @media only screen and (max-width: $md) {
      padding: 30px 0 45px;
    }
    @media only screen and (max-width: $sm) {
      flex-direction: column;
      align-items: flex-start;
    }
    &_left {
      min-width: 305px;
      margin-right: 15px;
      @media only screen and (max-width: $sm) {
        margin-bottom: 20px;
      }
    }
  }
  &__footer {
    font-size: 13px;
    line-height: 1.23;
  }
}
</style>
